.home{
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    flex-direction: column;

    &__text-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;

        h1{
            color: var(--selected-theme-main-color);
            margin: 0px;
            font-weight: 400;
            font-size: 25px;
            line-height: 3rem;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            animation: typingEffect 2.5s steps(30,end);
        }
    }

    .contact-me{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        margin-top: 10px;

        &__buttons-wrapper{
            display: flex;
            margin-left: 10px;
            gap: 10px;
            button, a{
                cursor: pointer;
                margin-top: 15px;
                padding: 10px 40px;
                --c: #fff;
                /* text color */
                background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
                    var(--selected-theme-main-color);
                /* background color */
                color: #0000;
                border: none;
                transform: perspective(500px) rotateY(calc(20deg*var(--_i, -1)));
                text-shadow: calc(var(--_i, -1)* 0.08em) -.01em 0 var(--c),
                    calc(var(--_i, -1)*-0.08em) .01em 2px #0004;
                outline-offset: .1em;
                transition: 0.3s;
            }

            button, a:hover,
            button, a:focus-visible {
                --_p: 0%;
                --_i: 1;
            }

            button, a:active {
                text-shadow: none;
                color: var(--c);
                box-shadow: inset 0 0 9e9q #0005;
                transition: 0s;
            }

            button, a{
                font-weight: bold;
                font-size: 2rem;
                margin: 0;
                cursor: pointer;
                padding: .1em .3em;
            }
        }

        &__socials-wrapper{
            display: flex;
            gap: 10px;

            svg{
                color: var(--selected-theme-main-color);
                cursor: pointer;
            }
        }
    }
}

@media only screen and (min-width: 390px) and (max-width: 844px){
    .home__text-wrapper h1{
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

@media only screen and (min-width: 551px) and (max-width: 768px){
    .home__text-wrapper h1{
        font-size: 2rem;
        line-height: 2rem;
    }
}
@media only screen and (min-width: 769px) and (max-width: 990px){
    .home__text-wrapper h1{
        font-size: 3rem;
        line-height: 3rem;
    }
}

@keyframes typingEffect{
    from{
        width: 0;
    }
    to{
        width: 100%;
    }
}