.portfolio {
    &__content {
        padding: 20px;
        width: 100%;

        &__filter {
            display: inline-block;
            margin: 0 auto;
            border-radius: 26px;
            border: 1px dashed var(--selected-theme-main-color);
            line-height: 0;
            text-align: center;

            li {
                font-size: 16px;
                display: inline-block;
                cursor: pointer;
                position: relative;
                color: var(--selected-theme-main-color);
                text-transform: uppercase;
                padding: 20px;
                border-radius: 26px;
                transition: all 0.5s ease;
                &.active{
                    background: var(--selected-theme-main-color);
                    color: var(--selected-theme-sub-text-color);
                    font-weight: 600;
                    border-radius: 26px;
                    animation: color_change 1s infinite alternate;
                }
            }
        }

        &__cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem;
            margin-top: 10px;

            &__item {
                aspect-ratio: 1/1;
                cursor: pointer;
                position: relative;

                &:hover .overlay {
                    opacity: 1;
                }

                &__img-wrapper {
                    aspect-ratio: 1/1;

                    a {
                        aspect-ratio: 1/1;

                        img {
                            height: inherit;
                            object-fit: cover;
                            width: 100%;
                        }
                    }
                }

                .overlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: 0.5s ease;
                    background-color: black;

                    div {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;

                        p {
                            color: var(--selected-theme-sub-text-color);
                            margin: 0;
                            font-weight: 500;
                            font-size: 2.9rem;
                            position: relative;
                            overflow: hidden;
                            animation: typingEffect 1s steps(20,end);
                            white-space: nowrap;
                        }

                        button {
                            cursor: pointer;
                            margin-top: 15px;
                            padding: 10px 70px;
                            --c: #fff;
                            /* text color */
                            background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p, 100%)/300% no-repeat,
                                var(--selected-theme-main-color);
                            /* background color */
                            color: #0000;
                            border: none;
                            transform: perspective(500px) rotateY(calc(20deg*var(--_i, -1)));
                            text-shadow: calc(var(--_i, -1)* 0.08em) -.01em 0 var(--c),
                                calc(var(--_i, -1)*-0.08em) .01em 2px #0004;
                            outline-offset: .1em;
                            transition: 0.3s;
                        }

                        button:hover,
                        button:focus-visible {
                            --_p: 0%;
                            --_i: 1;
                        }

                        button:active {
                            text-shadow: none;
                            color: var(--c);
                            box-shadow: inset 0 0 9e9q #0005;
                            transition: 0s;
                        }

                        button {
                            font-weight: bold;
                            font-size: 2rem;
                            margin: 0;
                            cursor: pointer;
                            padding: .1em .3em;
                        }
                    }
                }
            }
        }
    }
}

@keyframes typingEffect{
    from{
        width: 0;
    }
    to{
        width: 100%;
    }
}

@keyframes color_change {
	from { background-color: black; }
	to { background-color: var(--selected-theme-main-color); }
}

@media only screen and (max-width: 480px){
    .portfolio__content__filter > li{
        font-size: 11px;
    }

    .portfolio__content__cards{
        grid-template-columns: repeat(1, 1fr);
    }

}

@media only screen and (max-width: 481px) and (max-width: 768px){
    .portfolio__content__filter > li{
        font-size: 14px;
    }

    .portfolio__content__cards{
        grid-template-columns: repeat(2, 1fr);
    }

    .portfolio__content__cards__item .overlay div p{
        font-size: .75rem;
    }
}