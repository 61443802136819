.about{
    &__content{
        display: flex;
        gap: 20px;
        padding: 20px;
        width: 100%;

        &__personalWrapper{
            flex: 1;
            h3{
                margin-bottom: 10px;
                font-size: 2.5rem;
                color: var(--selected-theme-main-color);
                font-weight: 700;
            }

            h3.personalContent{
                padding-top: 20px;
            }

            p{
                color: var(--selected-theme-sub-text-color);
                font-size: 1.1rem;
                line-height: 1.65;
                padding-bottom: 20px;
                border-bottom: 1px dashed var(--selected-theme-main-color);
            }

            ul > li{
                line-height: 1rem;
                margin-bottom: 15px;

                span.title, span.value{
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    border-bottom: 2px solid var(--selected-theme-main-color);
                    position: relative;
                    display: inline-block;
                    margin-right: 16px;
                    padding-bottom: 1px;
                    color: var(--selected-theme-sub-text-color);
                }

                span.value{
                    border: none
                }
            }
        }

        &__servicesWrapper{
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &__innerContent{
                width: 450px;
                height: 450px;
                position: relative;
                border-radius: 50%;
                border: 1.5px solid var(--selected-theme-main-color);
                background: radial-gradient(250px, var(--selected-theme-main-color), transparent 90%);
                animation: rotation 10s linear 1s infinite;

                div:nth-child(1){
                    position: absolute;
                    bottom: -7%;
                    left: 42%;
                }
                div:nth-child(2){
                    position: absolute;
                    top: -8%;
                    left: 42%;
                }
                div:nth-child(3){
                    position: absolute;
                    top: 42%;
                    left: -6.7%;
                }
                div:nth-child(4){
                    position: absolute;
                    top: 42%;
                    left: 93.3%;
                }
                div:nth-child(5){
                    position: absolute;
                    top: 7%;
                    left: 78.3%;
                }
                div:nth-child(6){
                    position: absolute;
                    top: 79%;
                    left: 78.3%;
                }
                div:nth-child(7){
                    position: absolute;
                    top: 78%;
                    left: 6.3%;
                }
                div:nth-child(8){
                    position: absolute;
                    top: 10%;
                    left: 6.3%;
                }
            }
        }
    }
}

@keyframes rotation{
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 768px) {
    .about__content{
        flex-direction: column;

        &__servicesWrapper{
            margin-top: 20px;
            margin-bottom: 20px;

            &__innerContent{
                width: 250px;
                height: 250px;
                background: radial-gradient(100px, var(--selected-theme-main-color), transparent 120%);

                div:nth-child(1){
                    position: absolute;
                    bottom: -13%;
                    left: 42%;
                }
                div:nth-child(2){
                    position: absolute;
                    top: -13%;
                    left: 42%;
                }
                div:nth-child(3){
                    position: absolute;
                    top: 42%;
                    left: -10.7%;
                }
                div:nth-child(4){
                    position: absolute;
                    top: 42%;
                    left: 88.3%;
                }
                div:nth-child(5){
                    position: absolute;
                    top: 7%;
                    left: 77.3%;
                }
                div:nth-child(6){
                    position: absolute;
                    top: 74%;
                    left: 73.3%;
                }
                div:nth-child(7){
                    position: absolute;
                    top: 78%;
                    left: 6.3%;
                }
                div:nth-child(8){
                    position: absolute;
                    top: 2%;
                    left: 6.3%;
                }
            }
        }
    }
}