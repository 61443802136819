@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.player-container {
    text-align: center;
    font-family: Poppins;
    background-color: #212121;
    color: rgb(179, 61, 61);
    padding: 5px;
    position: relative;
    cursor: pointer;
    margin: 5px;
}

.song-name {
    cursor: pointer;
}

.player-container:hover {
    background-color: black;
}

.player-main {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    overflow: hidden;
}

.player-card {
    text-align: center;
    aspect-ratio: 1/1;
    width: 100%;
    height: 80vh;
    background-color: #212121;
    border-radius: 20px;
    -webkit-box-shadow: 13px 12px 40px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 13px 12px 40px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 13px 12px 40px -7px rgba(0, 0, 0, 0.75);
}

.control-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 30px;
}

.icons {
    box-shadow: 0px 0px 56px -5px rgba(252, 109, 25, 0.75);
    -webkit-box-shadow: 0px 0px 56px -5px rgba(252, 109, 25, 0.75);
    -moz-box-shadow: 0px 0px 56px -5px rgba(252, 109, 25, 0.75);
    border-radius: 50%;
}

.icons:hover {
    color: #aa1d1d !important;
    cursor: pointer;
}

.activeSong-name {
    color: white;
    font-family: Poppins;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 145px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
}

.activeArtist-name {
    color: rgb(255, 255, 255);
    font-family: Poppins;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 380px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
}

.activeArtist-duration {
    color: white;
    font-family: Poppins;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 230px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
}

.image-container {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.music-image {
    width: 200px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    box-shadow: 0px 0px 56px -5px rgba(15, 15, 15, 0.75) inset;
    -webkit-box-shadow: 0px 0px 56px -5px rgba(15, 15, 15, 0.75) inset;
    -moz-box-shadow: 0px 0px 56px -5px rgba(15, 15, 15, 0.75) inset;
}